import { merge } from 'lodash-es'
import { getDeviceReportDailyAPI } from '@/api/device-record'

export function useDeviceRecords() {
  const dailyDeviceReports = ref()
  const tableDataMap = ref<any>({})

  async function getDailyDeviceReport(params: any) {
    dailyDeviceReports.value = await getDeviceReportDailyAPI(params)
    getNameMap()
  }

  function getNameMap() {
    const map1 = getPropertyDateTimeValuesToTableData()
    const map2 = getSeriosData()
    tableDataMap.value = merge(map1, map2)
  }

  function getPropertyDateTimeValuesToTableData() {
    const propertyDateTimeValues = dailyDeviceReports.value.propertyDateTimeValues
    const map: any = {}
    propertyDateTimeValues.forEach((item: any) => {
      const { dateTimeValues, dataType } = item
      const mapValue = {
        headerColumns: [
          { title: '时间', dataIndex: 'time' },
          { title: item.name, dataIndex: 'value' },
        ],
        data: dateTimeValues.map((el: any) => ({ time: el.dateTime, value: getData(dataType, el.value) })),
      }

      map[item.name] = mapValue
    })

    return map
  }

  function getSeriosData() {
    const { series, dateTimes } = dailyDeviceReports.value
    const map: any = {}

    series.forEach((item: any) => {
      const { data, dataType, name } = item
      const mapValue = {
        headerColumns: [
          { title: '时间', dataIndex: 'time' },
          { title: item.name, dataIndex: 'value' },
        ],
        data: data.map((el: any, index: number) => ({ time: dateTimes[index], value: getData(dataType, el) })),
      }

      map[name] = mapValue
    })

    return map
  }

  function getData(dataType: any, value: any) {
    const { type, specs } = dataType
    switch (type) {
      case 'ENUM':
      case 'BOOL':{
        return specs[value]
      }

      default:
        return value || ''
    }
  }

  return { getDailyDeviceReport, dailyDeviceReports, tableDataMap }
}
