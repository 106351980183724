import { cloneDeep } from 'lodash-es'
import dayjs from 'dayjs'
import { transformDateToMilliseconds } from '@/utils/tools'

/* *
 * @description 重组结构体数据
 * @author 苏俊洋
 * @date 2023/3/15 16:53
 */
export function reBuildStructData(nextModelPropertiesValue: ThingModelNormal, value: any) {
  if (value == null)
    return
  const specs = nextModelPropertiesValue.dataType.specs
  // const structDatas: any[] = [];
  for (const [k, v] of Object.entries(value as object)) {
    const aimSpec = (specs as StructSpecs[]).find(item => item.identifier === k)
    if (aimSpec) {
      const obj: any = {}
      const type = aimSpec.dataType.type
      const spec = aimSpec.dataType.specs

      obj.label = aimSpec.name

      switch (type) {
        case 'BOOL':
        case 'ENUM': {
          obj.value = (spec as BoolSpecs | EnumSpecs)[v]
          const options: any[] = []
          for (const [key, value] of Object.entries(spec)) {
            const obj: any = {}
            obj.value = value
            if (type === 'ENUM') {
              obj.key = Number(key)
            }
            else {
              obj.key = Boolean(key)
            }
            options.push(obj)
          }
          aimSpec.dataType.datas = v;
          (aimSpec.dataType as any).options = options
          break
        }

        case 'INT':
        case 'DECIMAL': {
          aimSpec.dataType.datas = v
          break
        }

        case 'DATE': {
          aimSpec.dataType.datas = transformDateToMilliseconds(v)
          break
        }

        case 'TEXT': {
          aimSpec.dataType.datas = v
        }
      }
    }
  }
}

export function reStructDataFn(property: any, models: ThingModelNormal) {
  if (property == null || models == null)
    return []
  const nextProperties = cloneDeep(property)
  const nextModelProperties = cloneDeep(models)

  const returnData: ThingModelNormal[] = []
  for (const [key, value] of Object.entries(nextProperties)) {
    // if (value == null) continue;
    let restructureData: any = {}
    const nextModelPropertiesValue = nextModelProperties[key]

    if (nextModelPropertiesValue.dataType.type === 'STRUCT') {
      reBuildStructData(nextModelPropertiesValue, value)
    }
    else if (nextModelPropertiesValue.dataType.type === 'ARRAY') {
      const specs = nextModelPropertiesValue.dataType.specs as ArraySpecs
      const itemDataType = specs.item.type
      switch (itemDataType) {
        case 'INT':
        case 'DECIMAL': {
          const numSpecs = specs.item.specs as unknown as NumberSpecs
          const unit = numSpecs.unit
          const unitName = numSpecs.unitName
          const arr: string[] = [];
          (value as any[]).forEach((item) => {
            arr.push(`${item} ${unit || ''}${unitName ? `(${unitName})` : ''}`)
          })
          nextModelPropertiesValue.dataType.datas = arr
          break
        }
        case 'TEXT': {
          const arr: string[] = [];
          (value as any[]).forEach((item) => {
            arr.push(item)
          })
          nextModelPropertiesValue.dataType.datas = arr
          break
        }
        case 'STRUCT': {
          const arr: object[] = [];
          (value as any[]).forEach((item) => {
            const secondArr: any[] = []
            for (const [k, v] of Object.entries(item as object)) {
              const obj: any = {}
              const aimSpec = (specs.item.specs as StructSpecs[]).find(item => item.identifier === k)
              if (aimSpec) {
                const label = aimSpec.name
                switch (aimSpec.dataType.type) {
                  case 'INT':
                  case 'DECIMAL': {
                    const innerSpecs = aimSpec.dataType.specs as NumberSpecs
                    const unit = innerSpecs.unit
                    const unitName = innerSpecs.unitName
                    obj.label = label
                    obj.value = `${v} ${unit || ''}${unitName ? `(${unitName})` : ''}`
                    break
                  }

                  case 'TEXT': {
                    obj.label = label
                    obj.value = v
                    break
                  }

                  case 'DATE': {
                    obj.label = label
                    obj.value = dayjs(Number(v)).format('YYYY年MM月DD日 HH:mm:ss')
                    break
                  }

                  case 'ENUM': {
                    obj.label = label
                    obj.value = (aimSpec.dataType.specs as EnumSpecs)[v]
                    break
                  }

                  case 'BOOL': {
                    obj.label = label
                    obj.value = (aimSpec.dataType.specs as BoolSpecs)[v]
                    break
                  }
                }
              }

              secondArr.push(obj)
            }

            arr.push(secondArr)
          })

          nextModelPropertiesValue.dataType.datas = arr
          break
        }
      }
    }
    else if (nextModelPropertiesValue.dataType.type === 'DAY' || nextModelPropertiesValue.dataType.type === 'DATE') {
      nextModelPropertiesValue.dataType.datas = dayjs(value as any)
    }
    else if (nextModelPropertiesValue.dataType.type === 'ENUM') {
      nextModelPropertiesValue.dataType.datas = Number(value)
    }
    else {
      nextModelPropertiesValue.dataType.datas = value
    }

    restructureData = { ...nextModelPropertiesValue }
    returnData.push(restructureData)
  }

  return returnData
}
