<script setup lang="ts">
import { Empty, type TableColumnType, type TablePaginationConfig } from 'ant-design-vue'
import dayjs from 'dayjs'
import { queryHistoryDeviceEventApi } from '@/api/device'

const props = defineProps(['deviceRealTimeData'])
const eventType = ref('')
const eventTypeOptions = ref<any[]>([])

const state = reactive<TableState>({
  // 列表数据（必传）
  data: [],
  // 表头内容（必传，注意格式）
  columns: [
    { title: '上报时间', dataIndex: 'readAt', customRender({ record }) { return dayjs(record.readAt).format('YYYY年MM月DD日 HH:mm:ss') } },
  ],
  // 配置项（必传）
  config: {
    total: 0, // 列表总数
    loading: true, // loading 加载
    isBorder: false, // 是否显示表格边框
    isSerialNo: true, // 是否显示表格序号
    isSelection: true, // 是否显示表格多选
    isOperate: true, // 是否显示表格操作栏
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 10,
    pageSizeOptions: ['10', '20', '30', '40'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
})

watch(() => props.deviceRealTimeData, async () => {
  resetState()
  const { current, pageSize } = state.pagination as TablePaginationConfig
  initEventTypeOptions()

  await getEvents(current ?? 1, pageSize ?? 10)
  if (eventType.value)
    getTableHeader(eventType.value)
}, { immediate: true })

function resetState() {
  (state.pagination as TablePaginationConfig).current = 1;
  (state.pagination as TablePaginationConfig).pageSize = 10;
  (state.pagination as TablePaginationConfig).total = 0
  state.data = []
}

async function getEvents(current: number, size: number) {
  state.config.loading = true
  const res = await queryHistoryDeviceEventApi({ current, size, deviceCode: props.deviceRealTimeData.devices[0].deviceCode, eventCode: eventType.value } as HistoryEventOfDeviceParam)
  state.data = res.records ?? []

  setPagination(res)
  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

function setPagination(data: Paging<HistoryEventOfDevice>) {
  (state.pagination as TablePaginationConfig).current = data.current;
  (state.pagination as TablePaginationConfig).pageSize = data.size;
  (state.pagination as TablePaginationConfig).total = data.total
}

async function onTablePageChange(current: number, pageSize: number) {
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  await getEvents(current, pageSize)
}

function getTableHeader(eventCode: string) {
  state.columns.splice(1)
  if (!props.deviceRealTimeData.modelEvents)
    return

  if (props.deviceRealTimeData.modelEvents && Object.keys(props.deviceRealTimeData.modelEvents).length === 0)
    return

  if (props.deviceRealTimeData.modelEvents[eventCode] == null)
    return

  props.deviceRealTimeData.modelEvents[eventCode].outputData.forEach((item: any) => {
    if (item.dataType.type !== 'STRUCT' && item.dataType.type !== 'ARRAY') {
      let title = item.name
      const dataIndex = ['outputData', item.identifier]
      if (item.dataType.type === 'INT' || item.dataType.type === 'DECIMAL') {
        title = item.dataType.specs?.unit ? `${item.name} (${item.dataType.specs.unit})` : item.name
      }
      const obj = { title, dataIndex }
      setCustomRender(obj, item)
      state.columns.push(obj)
    }
  })
}

function setCustomRender(obj: TableColumnType, item: any) {
  const dataType = item.dataType.type
  if (dataType === 'BOOL' || dataType === 'ENUM') {
    obj.customRender = ({ record }) => {
      const specs = item.dataType.specs
      const key = record.outputData[item.identifier]
      return specs[key]
    }
  }
  if (dataType === 'DATE') {
    obj.customRender = ({ record }) => {
      return dayjs(record.outputData[item.identifier]).format('YYYY年MM月DD日 HH:mm:ss')
    }
  }
}

async function onChangeEventType(eventCode: string) {
  eventType.value = eventCode
  getTableHeader(eventType.value)
  await getEvents(1, 10)
}

// 初始化事件类型选项
function initEventTypeOptions() {
  eventTypeOptions.value = []
  eventType.value = ''
  if (!props.deviceRealTimeData.modelEvents)
    return

  const tempArr = [] as any[]

  const bindEventCodes: string[] = props.deviceRealTimeData?.devices?.[0]?.bindEventCodes ?? []
  for (const [key, value] of Object.entries<any>(props.deviceRealTimeData.modelEvents)) {
    if (bindEventCodes.includes(key))
      tempArr.push({ label: value.name, value: key })
  }
  eventTypeOptions.value = tempArr

  if (eventTypeOptions.value.length > 0)
    eventType.value = eventTypeOptions.value[0].value
}
</script>

<template>
  <a-card title="事件" style="width: 100%">
    <template #extra>
      <a-form layout="inline">
        <a-form-item label="事件类型">
          <a-select v-model:value="eventType" style="width: 200px;" :options="eventTypeOptions" @change="(eventType) => { onChangeEventType(eventType as any) }" />
        </a-form-item>
      </a-form>
    </template>
    <div v-if="state.data.length > 0" h-500px>
      <base-table v-bind="state" :need-header="false" @page-change="onTablePageChange" />
    </div>
    <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" description="暂无事件" />
  </a-card>
</template>

<style lang="scss" scoped></style>
