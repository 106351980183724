<script setup lang="ts">
import Video from './components/video.vue'
import Property from './components/property.vue'
// import Profile from './components/profile.vue'
// import Service from './components/service.vue'
import Event from './components/event.vue'
import { getWebSocket } from '@/utils/websocket/WebsocketAction'
import emitter from '@/utils/mitt'
import { getDeviceRealTimeDataApi } from '@/api/device-ability'
import { getVideoByDeviceCodeApi } from '@/api/video'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  deviceName: {
    type: String,
    default: () => '',
  },
  deviceCode: {
    type: String,
    default: () => '',
  },
})

const emit = defineEmits(['close'])

const online = ref(false)
const deviceRealTimeData = ref<DeviceRealTimeData>()
const videoDevice = ref<Video>()

watchEffect(async () => {
  if (props.visible) {
    await isVideoExist()
    await getDeviceRealTimeData()
    initOnlineStatus()
    onSubscribeDevice()
    listenOnOffLine()
  }
  else {
    // 取消订阅设备
    getWebSocket().onUnSubscribeDevice(props.deviceCode)
  }
})

async function isVideoExist() {
  videoDevice.value = await getVideoByDeviceCodeApi(props.deviceCode)
}

// 获取设备实时属性
async function getDeviceRealTimeData() {
  deviceRealTimeData.value = await getDeviceRealTimeDataApi(props.deviceCode)
}

function initOnlineStatus() {
  if (deviceRealTimeData.value?.devices && deviceRealTimeData.value.devices.length > 0) {
    online.value = deviceRealTimeData.value?.devices[0].online
  }
}

// 监听设备在线离线
function listenOnOffLine() {
  emitter.on('onDeviceOnOffLine', (data: any) => {
    online.value = data.online
  })
}

// 订阅设备
function onSubscribeDevice() {
  getWebSocket().onSubscribeDevice([props.deviceCode])
}

function onClose() {
  emit('close')
}
</script>

<template>
  <a-drawer
    root-class-name="drawer"
    placement="right"
    :closable="false"
    :open="visible"
    :get-container="false"
    :style="{ position: 'absolute' }"
    width="50%"
    @close="onClose"
  >
    <template #title>
      <div flex items-center>
        {{ deviceName ?? '' }}
        <a-tag :color="online ? 'green' : 'orange'" style="margin-left: 10px;">
          {{ online ? '在线' : '离线' }}
        </a-tag>
      </div>
    </template>
    <!-- 视频部分 -->
    <Video v-if="videoDevice?.channels && videoDevice.channels.length > 0" :device-code="deviceCode ?? ''" />
    <!-- 动态属性 -->
    <Property v-if="deviceRealTimeData" style="margin-top: 20px;" :device-real-time-data="deviceRealTimeData" />
    <!-- 静态信息 -->
    <!-- <Profile v-if="deviceRealTimeData" style="margin-top: 20px;" :device-real-time-data="deviceRealTimeData" /> -->
    <!-- 事件 -->
    <Event v-if="deviceRealTimeData" style="margin-top: 20px;" :device-real-time-data="deviceRealTimeData" />
    <!-- 服务 -->
    <!-- <Service v-if="deviceRealTimeData" style="margin-top: 20px;" :device-real-time-data="deviceRealTimeData" /> -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">
        关闭
      </a-button>
    </template>
  </a-drawer>
</template>

<style lang="scss" scoped>
:deep(.drawer){
  .ant-drawer-body{
    padding: 10px;
  }
}
</style>
