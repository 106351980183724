<script lang="ts" setup>
import { DownCircleOutlined, LeftCircleOutlined, RightCircleOutlined, UpCircleOutlined } from '@ant-design/icons-vue'
import type { SelectProps } from 'ant-design-vue'
import { Empty } from 'ant-design-vue'
import dayjs from 'dayjs'
import { getDeviceVideoUrl } from '@/utils/video'
import { controlVideoDeviceViewApi, controlVideoDirectionApi, getVideoByDeviceCodeApi, getVideoDeviceLiveApi, getVideoDevicePlayBackApi } from '@/api/video'

const props = defineProps({
  deviceCode: {
    type: String,
    default: '',
  },
})

const videoDevice = ref<Video>()
const channelName = ref<string>('')
// 视频url
const videoUrl = ref<string>('')
// 是否有进度 没进度表示直播
const seekable = ref<boolean>(false)
const channelOptions = ref<SelectProps['options']>([])
const isPlayBack = ref(false)

watchEffect(async () => {
  await getVideoData()
  await initChannelOption()
})

async function getVideoData() {
  videoDevice.value = await getVideoByDeviceCodeApi(props.deviceCode)
}

// 初始化选择的通道
async function initChannelOption() {
  channelName.value = ''
  channelOptions.value?.splice(0)
  if (!videoDevice.value)
    return
  if (videoDevice.value.channels.length === 0)
    return

  channelName.value = videoDevice.value.channels[0].name
  channelOptions.value = videoDevice.value.channels.map(channel => ({ label: channel.name, value: channel.name }))

  await onChangeChannel(channelName.value)
}

async function onChangeChannel(channelName: string) {
  const { seekable: seek, url, proxied } = await getVideoDeviceLiveApi(channelName, props.deviceCode)
  seekable.value = seek as boolean
  videoUrl.value = getDeviceVideoUrl(url ?? '', proxied ?? false) ?? ''
}

async function onVideoViewControl(videoView: VideoView) {
  await controlVideoDeviceViewApi(props.deviceCode, videoView)
}

async function onDirectionHandler(direction: VideoDirection) {
  await controlVideoDirectionApi(props.deviceCode, direction)
}

const date = ref()
const timePeriod = ref()

const isPlayBackDisabled = computed(() => {
  return !(date.value && timePeriod.value && timePeriod.value.length === 2)
})

async function onPlayBack() {
  const { dateStr, fromTimeStr, toTimeStr } = getPlayBackTimePeriod()

  const { url, proxied, seekable: seek } = await getVideoDevicePlayBackApi(props.deviceCode, dateStr, fromTimeStr, toTimeStr)
  videoUrl.value = getDeviceVideoUrl(url ?? '', proxied ?? false) ?? ''
  seekable.value = seek as boolean
  isPlayBack.value = true

  function getPlayBackTimePeriod() {
    const dateStr = dayjs(date.value).format('YYYY-MM-DD')
    const fromTimeStr = dayjs(timePeriod.value[0]).format('HH:mm:ss')
    const toTimeStr = dayjs(timePeriod.value[1]).format('HH:mm:ss')
    return { dateStr, fromTimeStr, toTimeStr }
  }
}

async function onPlayLive() {
  isPlayBack.value = false
  await onChangeChannel(channelName.value)
}

// 测试视频回放代码
// function onPlayBack() {
//   isPlayBack.value = true

//   const url = 'http://192.168.10.4:8070/backend-resource/h264.mp4'
//   const proxied = false

//   videoUrl.value = getDecideVideoUrl(url ?? '', proxied ?? false) as string
//   seekable.value = true
// }
</script>

<template>
  <a-card title="视频" style="width: 100%">
    <template #extra>
      <a-form v-if="!isPlayBack" layout="inline">
        <a-form-item label="通道">
          <a-select v-model:value="channelName" style="width: 200px;" :options="channelOptions" @change="(channelName) => { onChangeChannel(channelName as any) }" />
        </a-form-item>
      </a-form>
    </template>
    <div v-if="channelName !== ''">
      <div class="video" pos-relative>
        <video-player :visible="channelName !== ''" :has-audio="videoDevice?.audioEnabled ?? false" :video-url="videoUrl" :seekable="seekable" />
        <div class="video-position" pos-absolute>
          <a-button type="link" pos-absolute class="top" :disabled="!videoDevice?.directionEnabled" @click="onDirectionHandler('UP')">
            <UpCircleOutlined style="font-size: 30px;" />
          </a-button>
          <a-button type="link" pos-absolute class="bottom" :disabled="!videoDevice?.directionEnabled" @click="onDirectionHandler('DOWN')">
            <DownCircleOutlined style="font-size: 30px;" />
          </a-button>
          <a-button type="link" pos-absolute class="left" :disabled="!videoDevice?.directionEnabled" @click="onDirectionHandler('LEFT')">
            <LeftCircleOutlined style="font-size: 30px;" />
          </a-button>
          <a-button type="link" pos-absolute class="right" :disabled="!videoDevice?.directionEnabled" @click="onDirectionHandler('RIGHT')">
            <RightCircleOutlined style="font-size: 30px;" />
          </a-button>
        </div>
      </div>

      <div flex justify-between mt-10px>
        <a-popover v-if="!isPlayBack" title="视频回放区间" trigger="click">
          <template #content>
            <a-date-picker v-model:value="date" />
            <a-time-range-picker v-model:value="timePeriod" style="margin-left: 10px;" />
            <div style="display: flex;justify-content: flex-end;margin-top: 10px">
              <a-button type="primary" :disabled="isPlayBackDisabled" @click="onPlayBack">
                确定
              </a-button>
            </div>
          </template>
          <a-button style="height: 34px;width: 115px;">
            查看回放
          </a-button>
        </a-popover>
        <a-button v-if="isPlayBack" style="height: 34px;width: 115px;" @click="onPlayLive">
          结束回放
        </a-button>

        <radio-group text="焦点" :can-operate="videoDevice?.focusEnabled" @click-left="() => onVideoViewControl('REDUCE_FOCUS')" @click-right="() => onVideoViewControl('ADD_FOCUS')" />
        <radio-group text="光圈" :can-operate="videoDevice?.apertureEnabled" @click-left="() => onVideoViewControl('REDUCE_APERTURE')" @click-right="() => onVideoViewControl('ADD_APERTURE')" />
        <radio-group text="焦距" :can-operate="videoDevice?.zoomEnabled" @click-left="() => onVideoViewControl('REDUCE_ZOOM')" @click-right="() => onVideoViewControl('ADD_ZOOM')" />
      </div>
    </div>
    <a-empty v-if="channelName === ''" :image="Empty.PRESENTED_IMAGE_SIMPLE" description="暂无视频" />
  </a-card>
</template>

<style lang="scss" scoped>
.video{
  position: relative;
  width: 100%;
  height: 390px;
  .video-position{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba($color: #000000, $alpha: 0.1);
    bottom: 50px;
    right: 10px;
    .point{
      position: absolute;
    }
    .top{
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
    }
    .bottom{
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
    }
    .left{
      left: -12px;
      top: 50%;
      transform: translateY(-58%);
    }
    .right{
      right: -12px;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
