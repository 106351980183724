<script setup lang="ts">
import { MinusOutlined, PlusOutlined } from '@ant-design/icons-vue'

defineProps({
  text: { type: String, required: true },
  canOperate: { type: Boolean, default: false },
})

const emit = defineEmits(['clickLeft', 'clickRight'])

function onLeftClick() {
  emit('clickLeft')
}

function onRightClick() {
  emit('clickRight')
}
</script>

<template>
  <div class="radio-group" flex items-center>
    <a-button type="link" :disabled="!canOperate" @click="onLeftClick">
      <slot name="left">
        <MinusOutlined />
      </slot>
    </a-button>
    <div class="text">
      {{ text }}
    </div>
    <a-button type="link" :disabled="!canOperate" @click="onRightClick">
      <slot name="right">
        <PlusOutlined />
      </slot>
    </a-button>
  </div>
</template>

<style lang="scss" scoped>
.radio-group{
  width: fit-content;
  border-radius: 5px;
  border:1px solid var(--pro-ant-color-border);
  .text{
    margin:5px 0;
    padding: 0 10px;
    border-left:1px solid var(--pro-ant-color-border);
    border-right:1px solid var(--pro-ant-color-border);
  }
}
</style>
