<script setup lang="ts">
import { Empty } from 'ant-design-vue'
import dayjs from 'dayjs'
import { reStructDataFn } from './deviceRealTimeData'
import { updateDeviceRealTimeDataApi } from '@/api/device-ability'
import { transformDateToMilliseconds } from '@/utils/tools'

const props = defineProps(['deviceRealTimeData'])
const { getDailyDeviceReport, tableDataMap, dailyDeviceReports } = useDeviceRecords()
const formData = ref<any[]>([])
const deviceRecordsParam = ref({
  date: dayjs().format('YYYY-MM-DD'),
  granularity: 'MINUTE_30',
  deviceCode: '',
})
const granularityOptions = ref<{ label: string, value: string }[]>([
  { label: '按小时', value: 'HOUR_1' },
  { label: '按30分钟', value: 'MINUTE_30' },
  { label: '按10分钟', value: 'MINUTE_10' },
  { label: '按5分钟', value: 'MINUTE_5' },
])

const recordModalVisible = ref(false)
const recordName = ref()
const state = reactive({
  data: [],
  columns: [],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watchEffect(async () => {
  if (recordModalVisible.value === false) {
    deviceRecordsParam.value.date = dayjs().format('YYYY-MM-DD')
    deviceRecordsParam.value.granularity = 'MINUTE_30'
    recordName.value = undefined
  }

  const { devices, modelProperties } = props.deviceRealTimeData
  deviceRecordsParam.value.deviceCode = props.deviceRealTimeData.devices[0].deviceCode
  await getDailyDeviceReport(deviceRecordsParam.value)

  getTableData()

  formData.value = reStructDataFn(devices[0].properties, modelProperties).filter(item => !(item.dataType.type === 'ARRAY' || item.dataType.type === 'STRUCT'))
})

function getUnit(item: any) {
  if (item.dataType.specs.unit && item.dataType.specs.unitName) {
    return `${item.dataType.specs.unit} (${item.dataType.specs.unitName})`
  }
  if (item.dataType.specs.unit && !item.dataType.specs.unitName)
    return item.dataType.specs.unit
  if (!item.dataType.specs.unit && item.dataType.specs.unitName)
    return item.dataType.specs.unitName
  if (!item.dataType.specs.unit && !item.dataType.specs.unitName)
    return '无单位'
}

function getOptions(enumSpec: any) {
  const options = []
  for (const [key, value] of Object.entries(enumSpec)) {
    options.push({ value: Number(key), label: value })
  }

  return options
}

async function onNumberChange(num: any, item: any) {
  const deviceCode = props.deviceRealTimeData.devices[0].deviceCode
  const devicePropertyCode = item?.identifier ?? ''
  if (item.dataType.type === 'DATE') {
    num = transformDateToMilliseconds(num)
  }
  await updateDeviceRealTimeDataApi(deviceCode, devicePropertyCode, num)
}

function openRecords(name: string) {
  recordName.value = name
  recordModalVisible.value = true
  getTableData()
}

function getTableData() {
  if (!recordName.value)
    return
  const tableData = tableDataMap.value[recordName.value]
  state.columns = tableData.headerColumns
  state.data = tableData.data
}

async function onSearchRecords() {
  await getDailyDeviceReport(deviceRecordsParam.value)
}
</script>

<template>
  <a-card title="动态属性" style="width: 100%">
    <a-form v-if="formData.length > 0" :label-wrap="true" :label-col="{ span: 5 }">
      <a-form-item v-for="item in formData" :key="item.identifier" :label="item.name">
        <div flex>
          <div flex-1 mr-10px>
            <a-space v-if="item.dataType.type === 'INT' || item.dataType.type === 'DECIMAL'" style="width: 100%;" direction="vertical">
              <a-space-compact block>
                <a-input-number v-model:value="item.dataType.datas" :style="{ width: 'calc(100%)' }" :min="item.dataType?.specs?.min ?? 0" :max="item.dataType?.specs?.max">
                  <template #addonBefore>
                    {{ getUnit(item) }}
                  </template>
                </a-input-number>
                <a-button type="primary" :disabled="item.accessMode === 'READ_ONLY'" @click="onNumberChange(item.dataType.datas, item)">
                  修改
                </a-button>
              </a-space-compact>
            </a-space>
            <a-date-picker v-if="item.dataType.type === 'DAY'" v-model:value="item.dataType.datas" :disabled="item.accessMode === 'READ_ONLY'" format="YYYY-MM-DD" value-format="YYYY-MM-DD" @change="(data) => onNumberChange(data, item)" />
            <a-date-picker v-if="item.dataType.type === 'DATE'" v-model:value="item.dataType.datas" :disabled="item.accessMode === 'READ_ONLY'" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" @change="(data) => onNumberChange(data, item)" />
            <a-select v-if="item.dataType.type === 'ENUM'" v-model:value="item.dataType.datas" :disabled="item.accessMode === 'READ_ONLY'" :options="getOptions(item.dataType.specs)" @change="(data) => onNumberChange(data, item)" />
            <a-switch v-if="item.dataType.type === 'BOOL'" v-model:checked="item.dataType.datas" :disabled="item.accessMode === 'READ_ONLY'" :checked-children="item.dataType.specs.true" :un-checked-children="item.dataType.specs.false" @change="(data) => onNumberChange(data, item)" />
            <a-input-search v-if="item.dataType.type === 'TEXT'" v-model:value="item.dataType.datas" :disabled="item.accessMode === 'READ_ONLY'" enter-button="修改" @search="(data) => onNumberChange(data, item)" />
          </div>
          <a-button type="link" @click="openRecords(item.name)">
            历史值
          </a-button>
        </div>
      </a-form-item>
    </a-form>
    <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" description="尚未配置动态属性" />
    <a-modal v-model:open="recordModalVisible" width="50%" :title="recordName" :footer="null" centered>
      <a-form :model="deviceRecordsParam" layout="inline" mt-10px>
        <a-form-item label="划分时间段" name="granularity">
          <a-select
            v-model:value="deviceRecordsParam.granularity" :options="granularityOptions"
          />
        </a-form-item>
        <a-form-item label="日期" name="date">
          <a-date-picker v-model:value="deviceRecordsParam.date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearchRecords">
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <div h-500px mt-10px>
        <base-table v-bind="state" />
      </div>
    </a-modal>
  </a-card>
</template>

<style lang="scss" scoped></style>
