/**
 * 日报表
 */
export const getDeviceReportDailyAPI = async (params: any) => useGet('/runtime/history-device-property/report/daily', params)

/**
 * 日期段报表
 */
export const getDeviceReportDatePeriodAPI = async (params: any) => useGet('/runtime/history-device-property/report/datePeriod', params)

/**
 * 年报表
 */
export const getDeviceReportYearAPI = async (params: any) => useGet('/runtime/history-device-property/report/year', params)
